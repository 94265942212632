.app-btn {
    padding: 15px 40px;
    border-width: 2px;
    font-weight: 400 !important;
    border-radius: $border-radius-lg;
}

.btn-primary, .btn-outline-primary {
    &:hover { color: #fff; }
}
.btn-primary {
    color: #fff;
}
.btn-lg {
    font-size: 105%;
    padding: 18px 34px;
}