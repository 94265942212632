.anuncio-carousel-control {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        width: 5%;
        margin: 0;
        padding: 0;

        cursor: pointer;
        opacity: .25;
        transition: opacity .2s ease-in-out;

        &:hover, &:focus, &.active {
            opacity: 1;
        }
    }
}