label {
    font-weight: bold;
    margin-bottom: 3px;
}

.form-control {

    border-width: 2px;
        
    &:focus {
        
        border-color: $brand-primary-color;
        box-shadow: none;
    }
}

.form-text {
    font-size: 85%;
    color: #aaa;
    margin-left: 1px;
    padding-left: 10px;
    border-left: #eee 1px solid;
}

.input-group-text {
    font-size: 80%;
    border-width: 2px;
    background: #fff;
}

.input-group-prepend {

    .input-group-text {
        border-right: none;
    }

}