.estoque-body {
    background: #eee;
    padding: 45px 0 0 0;
    
    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 65px 0 0 300px;
    }
}

.estoque-main {
    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 0 15px 70px 15px;
    }
}

.estoque-barra-superior {
    padding: .5rem 0;
    .btn { margin: 1rem 0 0 0; }
}

.estoque-sidebar {

    position: relative;
    padding: .75rem 0;
    width: 100%;
    background: #fff;

    form { width: 100%; }

    strong { border-bottom: #333 2px solid; }

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        position: fixed;
        top: 65px;
        padding-bottom: 65px;
        left: 0;
        max-width: 300px;
        height: 100vh;
        overflow-y: scroll;
    }

    .form-group {
        padding: 10px 0 20px 0;
        margin: 0 -20px 0 -20px;

        &.gray {
            background: #f4f4f4;
        }
    }

    .form-control {
        font-size: 85%;
    }
}
.estoque-sidebar-form-item {

    margin: 0 -20px 10px -20px;
    padding: 30px 20px;

    &.darken {
        background: #eee;
    }
}
.estoque-sidebar-submit {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-top: 1rem;

    .btn {
        display: block;
        padding: 1rem 0;
        width: 100%;
        color: #fff;
        text-transform: uppercase;
    }
}

// Itens do estoque

.estoque-container {
    position: relative;
    width: 100%;
    height: auto;
    margin: 10px 0;
    overflow: hidden;
    border-radius: $border-radius-lg;

    &.destaque {

        background: $primary;
        
        .estoque-detalhe {
            color: #fff;
            background: darken($primary, 10);
        }
    }
}

.estoque-imagem {
    position: relative;
    display: block;
    width: 100%;
    height: 59.5%;
    background: #ddd;
}

.estoque-titulo {
    display: flex;
    padding: 25px;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 108px;
    background: #f7f7f7;

    .h2, .h3 {
        display: block;
        text-transform: uppercase;
    }

    .h2 {
        font-size: 76%;
        margin-bottom: 0;
        line-height: 10px;
        font-weight: 700;
    }

    .h3 {
        font-size: 130%;
        margin-bottom: 0;
        font-weight: 400;
    }
}

.estoque-titulo-marca {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 60px;
    height: 60px;
}

.estoque-detalhe {
    display: flex;
    margin-top: 1px;
    padding: 0 25px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 108px;
    background: #f7f7f7;

    .estoque-detalhe-parametro {
        font-size: 80%;
        font-weight: 700;
        text-align: center;
    }
}

.estoque-valor {

    .estoque-valor-texto {
        display: block;
        line-height: 1;
        font-weight: 700;
        font-size: 160%;
        small { font-weight: 400; font-size: 50%; }
    }
}


.estoque-botao {
    position: relative;
    background: $primary;
    transition: background .2s ease-out, padding .2s ease-out;
    
    &:hover, &:active, &:focus {
        padding-left: 3px;
        background: #333;

        a {
            background-position: 103%;
        }
    }

    a {
        display: block;
        padding: 23px;
        font-weight: 700;
        font-size: 95%;
        text-transform: uppercase;
        color: #fff;

        @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
            background: url('../svgs/asa-btn.svg') no-repeat center right;
            background-size: contain;
            transition: background .2s ease-out;
        }
        
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }
}

.inicio-estoque-visitar {
    position: relative;
    padding: 35px 0 50px 0;
    
    .btn {
        color: #fff;
        padding: 20px 20px;
        text-transform: uppercase;
        font-size: 120%;
        box-shadow: rgba(0, 0, 0, 0.25) 0 1px 50px;
        transition: transform .2s ease-out, background .2s ease-out; 
        border: none;

        &:hover, &:focus {
            background: #333;
            border: none;

            @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                transform: scale(1.05);
            }
        }
    }
}
.inicio-estoque-visitar-asa {

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        position: absolute;
        top: -22px;
        right: -44px;
        width: 60px;
        height: 100px;
        background: url(../svgs/asa-direita.svg) no-repeat center center;
        background-size: contain;
        z-index: -50;

        &.esquerda {
            background: url(../svgs/asa-esquerda.svg) no-repeat center center;
            left: -44px;
        }
    }
}

.estoque-loading {
    position: relative;
    width: 100%;
    height: 300px;
    background: url('../images/request-loading.gif') no-repeat center center;
}

.estoque-sidebar form {
    padding: 15px 35px;
    min-height: 100%;
}
.estoque-sidebar-submit {
    display: block;
    position: sticky;
    bottom: 15px;
    left: 0;
    width: 100%;
}
.estoque-opcao {
    position: relative;
    
    .estoque-opcao-acoes {
        position: absolute;
        top: -15px;
        right: 15px;
        z-index: 10;

        .btn {
            background: #fff;

            &:hover, &:active, &:focus, .active {
                background: $primary;
                i { color: #fff; }
            }

            i { color: $primary; }
        }
    }
}