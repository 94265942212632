.concessionaria {

    display: flex;
    align-items: center;
    position: relative;
    padding: 80px 0 200px 0;
    text-align: center;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 150px 0;
        text-align: left;
        overflow: hidden;
    }    

    .background {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: -150;
        top: 0;
        height: 100%;
    }

    .concessionaria-cidade {
        margin: 1.5rem 0;
        display: flex;
        align-items: center;
    
    }

    &.viviani-veiculos {

        position: relative;
        z-index: 200;

        .h2, p, .text-upper-emphasis { color: #fff; }

        .btn-outline-primary {
            border-color: #fff; color: #fff;
            
            &:hover, &:active, &:focus {
                color: #333;
                background: #fff;
            }
        }

        .background {
            left: 0;
            width: 100%;
            background: url('../images/concessionaria/viviani-veiculos-background.jpg') no-repeat left center;
            
            @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                width: 70%;
            }

            .veiculo {
                position: absolute;
                bottom: -75px;
                right: 0;
                width: 100%;
                height: 300px;
                background: url('../images/concessionaria/viviani-veiculos-overlay.png') no-repeat center center;
                background-size: contain;
                z-index: 200;

                @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                    right: -456px;
                    width: 690px;
                    height: 472px;
                    bottom: 35px;
                }
            }
        }  

    }

    &.viviani-motors {

        .background {
            position: absolute;
            right: 0;
            width: 100%;
            z-index: -150;
            background: url('../images/concessionaria/viviani-motors-background.jpg') no-repeat right center;

            @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                width: 70%;
            }

            .veiculo {

                position: absolute;
                left: 10%;
                width: 80%;
                height: 200px;
                bottom: 0;
                background: url('../images/concessionaria/viviani-motors-overlay.png') no-repeat center center;
                background-size: contain;
                z-index: 50;
                
                @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                    left: -310px;
                    width: 506px;
                    height: 300px;
                    bottom: 100px;
                }
                
            }
        }
    }

    &.jeep-viviani {

        padding-bottom: 100px;

        @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
            padding-bottom: 200px;
        }

        .h2, p, .text-upper-emphasis { color: #fff; }

        .concessionaria-switcher {

            a {
                background: #111;

                &:hover { background: #fff; color: #333; }
            }

        }

        .background {
            position: absolute;
            width: 100%;
            left: 0;
            background: #fff url('../images/concessionaria/jeep-viviani-background.jpg') no-repeat left center;
            z-index: -150;

            @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                width: 70%;
            }

            .veiculo {
                position: absolute;
                width: 80%;
                right: 10%;
                height: 300px;
                bottom: -150px;
                background: url('../images/concessionaria/jeep-viviani-overlay.png') no-repeat center center;
                background-size: contain;

                @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                    right: -381px;
                    height: 373px;
                    width: 554px;
                    bottom: 115px;
                }
            }
        }        
    }

    &.viviani-ram {

        .background {
            position: absolute;
            right: 0;
            width: 100%;
            z-index: -150;
            background: url('../images/concessionaria/ram-viviani-background.jpg') no-repeat right center;

            @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                width: 70%;
            }

            .veiculo {

                position: absolute;
                left: 10%;
                width: 80%;
                height: 200px;
                bottom: 0;
                background: url('../images/concessionaria/ram-overlay.png') no-repeat center center;
                background-size: contain;
                z-index: 50;
                
                @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
                    left: -310px;
                    width: 634px;
                    height: 380px;
                    bottom: 100px;
                }
                
            }
        }
    }
}