
.footer {
    text-align: center;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        text-align: left;
    }
}


.footer-social {
    padding: 30px 0;
    border-bottom: $gray-200 1px solid;
    border-top: $gray-200 1px solid;

    ul {
        margin: 0;
    }
}
.footer-social-col {

    margin-bottom: 10px;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        display: flex;
        align-items: center;
    }

}

.footer-logo {
    position: relative;
    width: 100%;
    height: auto;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        width: 75%;
    }
}
.footer-nav-col {
    margin-top: 60px;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        margin-top: 0;
    }
}
.footer-nav-title {
    color: darken(#a2a2a2, 15);
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 110%;
}
.footer-nav {
    margin: 60px 0;
}
.footer-nav-list {
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
    font-size: 90%;

    a {
        text-decoration: underline;
        color: darken(#a2a2a2, 5);

        &:hover, &:focus, &:active {
            color: darken(#a2a2a2, 20);
            text-decoration: none;
        }
    }
}
.footer-creditos {
    padding: 20px 0 5px 0;
    border-top: $gray-200 1px solid;
    color: #a0a0a0;
    font-size: 90%;

    a {
        text-decoration: underline;
        color: #000;

        &:hover { text-decoration: none; }
    }
}