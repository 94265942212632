.barra-titulo {
    .h1 { color: $primary; }
}

.app-subtitulo-container {
    background: #eee;
    margin: -52px 0 -52px 0;
    padding: 60px 0 30px 0;
}

.app-subtitulo {
    position: relative;
}

.app-breadcrumbs {

    &.offset {
        .breadcrumb {
            padding: 0 0 0 80px;
        }
    }

    .breadcrumb {
        padding: 0;
        background: none;
        text-transform: lowercase;
    }
}

.box {
    background: #fff;
    padding: 25px;
    border-radius: $border-radius-lg;
    box-shadow: rgba(0, 0, 0, .125) 0 1px 5px;
    overflow: hidden;

    .table td, .table th {
        padding: 7px 14px;
    }

    .box-header, .box-footer {
        position: relative;
        padding: 25px;
    }
    .box-header {
        margin: -25px -25px 25px -25px;
        border-bottom: #eee 1px solid;
    }
    .box-header-border {
        position: absolute;
        bottom: -2px;
        width: 60px;
        height: 4px;
        background: $primary;
    }
    .box-footer {
        margin: 25px -25px -25px -25px;
        background: #fff6ed;
    }

    &.box-valor {
        color: #fff;
        background: $primary;

        .box-valor-numero {
            display: block;
            font-size: 230%;
            font-weight: bold;
            line-height: 1.25;
        }
    }
}

.no-border-left {
    border-left: none !important;
}
.no-border-right {
    border-right: none !important;
}

.app-modal {

    font-size: 100%;

    .modal-header {
        position: relative;
        border-bottom: #eee 1px solid;

        .linha {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 40%;
            height: 4px;
            background: $primary;
        }
    }

    .modal-footer {
        background: #fff6ed;
    }

}

.btn-primary {
    color: #fff !important;
}