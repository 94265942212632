.h2 {
    text-transform: uppercase;
    font-weight: 700;
}

.text-invert {

    .h1, .h2, .h3, .h4, .h5, .h6, .text-h-emphasis { color: #fff; }

    color: #fff;

    .text-h-emphasis { border-bottom-color: #fff; }

    .btn { border-color: #fff; color: #fff; }
}

.text-h-emphasis {
    color: #333;
    border-bottom: #333 2px solid;

    &.text-primary { border-color: $primary; }
}

.text-upper-emphasis {
    color: #2d2d2d;
    font-weight: 700;
    letter-spacing: .025rem;
    font-size: 90%;
    text-transform: uppercase;
}

.text-invert {
    color: #fff;
}