.app-navbar {
    background: $app-navbar-background;
    padding: 0;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 0 0 0 2rem;
    }

    .navbar-toggler {
        padding: 15px 14px;
        font-size: 120%;
    }

    .nav-link {
        padding: 12px 19px;
        margin: 0 20px -2px 0;
        border-bottom: #555 1px solid;
        color: white;

        @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
            border-bottom: transparent 3px solid;
            padding: 19px;
        }
        
        &:hover, &:active, &.active { color: $primary; border-bottom-color: $primary; }
    }
    
}

#navbarMain {
    background: #444;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        background: none;
    }
}

.navbar-brand {
    padding-top: 0;
}

.app-navbar-grupo-viviani {
    
    padding: 10px 15px;
    background: darken($app-navbar-background, 20);
    
    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 20px 40px;
    }
}