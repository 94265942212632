// Typography
$font-family-sans-serif: 'Be Vietnam', sans-serif;
$font-size-base: 0.85rem;
$font-weight-base: 400;
$line-height-base: 1.9;

// Navbar
$app-navbar-background: #2d2d2d;
$app-navbar-height: 65px;

$footer-height: 300px;
$btn-line-height: 1rem;
$btn-line-height-lg: 1rem;

// Colors
$brand-primary-color: #f79633;
$primary: #f79633;

$link-color: $brand-primary-color;

// Font Awesome
$fa-font-path: "../fonts" !default;
$fa-font-size-base: 10px !default;

// Forms
$input-border-color: #eee;
$input-border-width: 1px;
$input-border-focus: $brand-primary-color;

// Buttons
$btn-font-weight: 700;
$btn-primary-color: #fff;