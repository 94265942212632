// Fontes
@import url('https://fonts.googleapis.com/css?family=Be+Vietnam:400,700&display=swap');

// Variáveis
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/css/all';

body {
    padding-top: $app-navbar-height;
}

// Typos
@import 'typos';

// Buttons
@import 'buttons';

// Headers
@import 'navbar';

// Forms
@import 'forms';

// Footer
@import 'footer';

@import 'utils';

.propaganda-inicial {
    position: relative;
    width: 100%;
    height: auto;
}

.busca-inicial {
    margin-bottom: 50px;
}

.busca-inicial-formulario {
    padding: 2rem 0 1.75rem 0;
    background: white;
}

.busca-inicial-lista {
    
    li {
        display: inline-block;
    }

    a {
        color: #a0a0a0;
        display: inline-block;
        margin-right: 10px;
        text-decoration: underline;

        &:hover, &:active, &:focus { color: $primary; text-decoration: none; }
    }
}

.busca-inicial-rodape {

    position: relative;
    padding: 1.25rem 0 2.25rem 0;
    width: 100%;

    .busca-inicial-rodape-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $gray-100;
        box-shadow: rgba(0, 0, 0, 0.125) 0 3px 100px;
        z-index: -50;
    }
}

.busca-inicial-botao {

    position: sticky;
    bottom: 15px;

    .btn {
        color: #fff;
        border: none;
        transition: transform .2s ease-out, background .2s ease-out;

        &:hover, &:focus {
            background: #333;
            border: none;
            transform: scale(1.05);
        }
    }
    
    .btn-lg {
        margin-top: -30px;
        padding: 20px;
        text-transform: uppercase;
        box-shadow: rgba(0, 0, 0, 0.125) 0 1px 40px;
    }
}

// Estoque
@import 'estoque';

.busca-por-marcas {
    position: relative;
    width: 100%;
    max-width: 100%;
    border-top: #e9ecef 1px solid;
    padding: 30px 0;
    text-align: center;
    
    .marcas-dragdealer {
        position: relative;
        height: 32px;
        overflow: hidden;
        margin-top: 20px;

        @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
            margin-top: 0;
        }

        .handle {
            position: absolute;
            top: 0;
            left: 0;
            cursor: url('../images/cursor/openhand.cur'), move;

            &:active { cursor: url('../images/cursor/closehand.cur'), move; }
          }
    }
}

// Concessionária
@import 'concessionaria';

.inicio-central {
    position: relative;
    padding: 0;
    text-align: center;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 50px 0 200px 0;
        text-align: right;
    }

    .btn-outline-primary {
        &:hover { background: #fff; color: $primary; }
    }

    .telefone {
        display: inline-block;
        padding: 2px 25px;
        font-size: 150%;
        font-weight: bold;
        border: #000 2px solid;
        border-radius: 8px;

        @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
            font-size: 200%
        }

        a {
            color: #333;
            text-decoration: none;
            line-height: 1;
        }

        .icone {
            display: inline-block;
            margin: 0 10px 0 0;
            width: 30px;
            height: 30px;
            background: url('../svgs/telefone.svg') no-repeat center center;
            background-size: contain;
        }
    }
}
.inicio-central-telefone {
    background: $gray-200;
    padding-bottom: 50px;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        background: none;
    }
}
.inicio-central-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $primary;
    z-index: -50;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        width: 50%;
        &.gray { left: 50%; background: $gray-100; }
    }

}
.inicio-central-bg-imagem {
    position: absolute;
    bottom: -50px;
    left: 5%;
    width: 90%;
    height: 15%;
    background: url(../images/intro/central-de-vendas.png) no-repeat center center;
    background-size: contain;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        left: 7.5%;
        width: 75%;
        height: 312px;
        bottom: -115px;
    }
}

.inicio-consorcio {
    position: relative;
    padding: 80px 0 0 0;
    text-align: center;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 194px 0 100px 0;
        text-align: left;
    }

    .background {
        position: relative;
        margin-top: 50px;
        width: 100%;
        height: 338px;
        background: #eee;

        @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
            position: absolute;
            top: 50%;
            margin-top: -140px;
            right: 0;
            width: 567px;
            height: 338px;
        }
    }
    .overlay {
        position: absolute;
        top: 300px;
        right: 567px;
        width: 142px;
        height: 88px;
        background: url('../images/consorcio/overlay.png') no-repeat center center;
        background-size: contain;
    }
}

.inicio-seguros {
    padding: 100px 0;
    text-align: center;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        text-align: left;
    }
}

.inicio-venda {
    position: relative;
    padding: 60px 0;
}

.inicio-venda-bg-gray, .inicio-venda-bg-primary {
    position: absolute;
    bottom: 0;
    z-index: -50;
}
.inicio-venda-bg-gray {
    left: 0;
    width: 60%;
    height: 110%;
    background: $gray-100;
}
.inicio-venda-bg-primary {
    right: 0;
    width: 40%;
    height: 100%;
    background: $primary;
}
.inicio-venda-imagem {
    position: absolute;
    bottom: -70px;
    left: -100px;
    width: 550px;
    height: 385px;
    background: url('../images/intro/mobi-like.png') no-repeat center center;
    background-size: contain;
}

.s-titulo {
    position: relative;
    width: 100%;
    padding: 50px 0;
    background: #eee;
}

@import 'anuncio' ;

.fale-conosco-telefone-central {
    font-size: 150%;
    font-weight: 700;

    .icone {
        display: inline-block;
        margin: 0 10px -6px 0;
        width: 30px;
        height: 30px;
        background: url('../svgs/telefone.svg') no-repeat center center;
        background-size: contain;
    }
}
.box-imagem {
    margin: 50px -25px -25px -25px;
}

.whatsapp {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 30px;
    right: 30px;
    width: 64px;
    height: 64px;
    background: #f79633;
    border-radius: 50%;
    z-index: 5000;

    svg, a {
        margin-top: -2px;
        color: #fff;
        fill: #fff;
    }
}